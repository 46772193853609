@import "config.scss";

.ui-datepicker{
    border-radius: 0 !important; 
    background: map-get($colors,nine);
    color: #FFF;
    font-family: map-get($fonts,two);  
}
.ui-datepicker .date-td-valor a{
    position: relative;
    cursor: pointer; 
}
.ui-datepicker th{
    color: map-get($colors,nine) !important;   
}
.ui-datepicker .date-td-valor{
    position: relative;
}
.ui-datepicker .date-td-valor .valor{
    font-size: 11px;
    position: absolute;
    padding: 0;
    bottom: 2px;
    text-align: center;
    width: 100%;
    color: #000;
    cursor: pointer;
}
.ui-datepicker-trigger{   
    width: 30px;
    position: absolute;
    right: 0;
    top: 25px;
    cursor: pointer;
}

.ui-datepicker .date-td-valor.ui-datepicker-current-day .valor{
    color: #FFF !important;
}
.ui-datepicker .ui-datepicker-month,
.ui-datepicker .ui-datepicker-year{
    border-radius: 5px;
    padding: 4px;
    margin: 0 5px 0 0 !important; 
    font-size: 12px !important;
}
.ui-datepicker .ui-datepicker-header{
    border-radius: 0;
    background: #FFF;
    padding: 10px !important;
}
.ui-datepicker .ui-datepicker-today a{
    background: map-get($colors,five);
    border-color: map-get($colors,five);
    color: #FFF;
}
.ui-datepicker .ui-state-active{
    background: map-get($colors,two);
    color: #FFF;
    border-color: map-get($colors,two);
}
/** DATEPICKER **/
.dm-cart-data-servico .ui-datepicker{
    background: #FFF !important;
    width: 100%;
}
.dm-cart-data-servico .ui-datepicker a{
    text-align: center;
}
.dm-cart-data-servico .ui-datepicker .ui-datepicker-today a{
    background: map-get($colors,one) !important;
    color: #FFF !important;
    border-color: map-get($colors,one) !important;
}
.dm-cart-data-servico .ui-datepicker .ui-datepicker-header{
    border-radius: 0;
    background: #f4b64f !important;
    color: #FFF;
}
.dm-cart-data-servico .ui-datepicker .ui-datepicker-title{
    font-family: map-get($fonts,one) !important;
    text-transform: uppercase;
    font-weight: normal !important;
}
.dm-cart-data-servico .ui-datepicker .ui-state-active{
    background: map-get($colors,one) !important;
    color: #FFF !important;
    border-color: map-get($colors,one) !important;
}
.dm-cart-data-servico .ui-datepicker .ui-datepicker-next,
.dm-cart-data-servico .ui-datepicker .ui-datepicker-prev{
    background: #FFF;
}
.dm-cart-data-servico .date-td span,
.dm-cart-data-servico .date-td a{
    padding: 10px 12px;
    text-align: center;
    font-size: 16px;
}
.ui-datepicker-prev, .ui-datepicker-next {
    display: inline-block !important;
    width: 30px !important;
    height: 25px !important;
    text-align: center !important;
    cursor: pointer !important;
    background-repeat: no-repeat !important;
    line-height: 600% !important;
    overflow: hidden !important;
    background-size: 50% !important;
    height: 85% !important;
}
/*.ui-datepicker-prev {
    float: left !important;
    background-position: 8px !important;
    background-image: url('/images/icons/seta-prev.png') !important;
}
.ui-datepicker-next {
    float: right !important;
    background-image: url('/images/icons/seta.png') !important;
    background-position: 8px !important;
}*/
.input-date .ui-datepicker-trigger{
    top: 27px !important;
}