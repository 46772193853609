/*--
	reset
--*/ 
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{border:none;outline:0;font-weight:inherit;font-style:inherit;font-size:100%;text-decoration:none;margin:0;padding:0;}
table{border-collapse:separate;border-spacing:0}
blockquote:before,blockquote:after,q:before,q:after{content:""}
*{margin:0;padding:0;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block; }								  
ol, ul { list-style: none;}
blockquote, q { quotes: none;}
:focus { outline: 0;}
table { border-collapse: collapse; border-spacing: 0;}
img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }
a{text-decoration:none; position:relative; color:#000;}
audio,video,canvas {max-width: 100%;}
 