@import "config.scss";

/** DM CHECKBOX **/
.dm-check-box{
    display: table;
    margin: 0 0 5px 0;
}
.dm-check-box-input{
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: table;
    border: 2px solid #eee;
    background: #FFF;
    margin: 0 10px 0 0;
    cursor: pointer;
}
.dm-check-box-input.active{
    background: map-get($colors,two);
}
.dm-check-box-input,
.dm-check-box-value{
    display: table;
    float: left;
}
.dm-check-box-value{
    text-transform: uppercase;
    position: relative;
    top: -2px;
}
.dm-check-box-info{
    font-size: 10px;
    color: map-get($colors,one);
    top: -2px;
}
/** MODAL DIALOG **/
.modal-dialog .message-text{
    padding: 10px;
}
.produto-form-search-two .mat-select-value-text{
    font-size: 12px;
    color: map-get($colors,thirty);
    position: relative;
    top: 4px;
}
/** FORM CUSTOM **/
.form-custom label{
    float: left;
    position: relative;
    width: 100%;
    text-align: left;
    font-weight: 500;
    color: map-get($colors,three);
    font-size: 13px;
    font-weight: 500;
}
.form-custom label.bold{
    font-weight: 700;
}
.form-custom label i{
    padding-right: 12px;
    font-size: 14px;
    position: absolute;
    top: 16px;
    left: 16px;
    color: map-get($colors,thirty-two);
}
.form-custom input[type="text"],
.form-custom input[type="password"],
.form-custom textarea,
.form-custom mat-select{
    float: left;
    border: none;
    background: transparent; 
    width: 100%;
    padding: 10px 0;
    border-radius: 0;
    color: map-get($colors,three);
    font-size: 13px;
    -webkit-appearance: none;
    border-bottom: 1px solid #9D9D9C;
    outline: none;
}
.form-custom input::-ms-input-placeholder{
    font-size: 13px;
}
.form-custom input::-webkit-input-placeholder{
    font-size: 13px;
}
.form-custom input::placeholder{
    font-size: 13px;
}
.form-custom textarea::-ms-input-placeholder{
    font-size: 13px;
}
.form-custom textarea::-webkit-input-placeholder{
    font-size: 13px;
}
.form-custom textarea::placeholder{
    font-size: 13px;
}
.form-custom textarea{
    resize: none;
    -webkit-appearance: none;
}
.form-custom mat-select{
    padding: 8px !important;
}
.form-custom mat-select .mat-select-value-text{
    position: relative;
    top: 5px;
    font-size: 12px;
}
.form-group{
    display: table;
    width: 100%;
    margin: 0 0 15px 0;
}
/** select 2 **/
.select2-container .select2-selection--single{
    height: 45px;
    outline: none !important;
    padding: 7px;
    background: #f6f6f6 !important;
    border-color: #eee;
    border-radius: 5px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b{
    top: 80%;
    margin-left: -10px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    color: #000;
    font-size: 13px;
}
.select2,.select2-hidden-accessible{
    /**width: 100% !important;**/
    max-width: 100% !important;
    table-layout: fixed;
    display: table;
}
.select2{
    width: 100% !important;
}
.select2-results__option[aria-selected]{
    padding: 15px !important;
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background-color: map-get($colors,nine) !important;
}
.input-box{
    margin: 0 0 15px 0;
    display: table;
    width: 100%;
}
.input-float input,.input-float textarea{
    border: 1px solid map-get($colors,three) !important;
    padding: 15px 20px !important;
    border-radius: 10px !important;
}
.input-float label{
    background: #FFF;
    position: absolute;
    display: table;
    width: auto;
    padding: 0 10px;
    color: map-get($colors,three);
    margin-left: 10px;
    margin-top: -5px;
}
.input-float{
    margin-bottom: 10px;
    display: table;
    width: 100%;
}