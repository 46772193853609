

@import "./config.scss";

.center-flex{
      display: flex;
      align-items: center;
  }
  .module h2{
      color: #353535;
      font-size: 26px;
      font-weight: 700;
  }
  .module h3{
      color: #353535;
      font-size: 18px;
      font-weight: 200;
      margin: 8px 0 0 0;
  }
  .module-content{
      position: relative;
  }
  .slide-navigate-item{
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      border-radius: 100%;
      position: absolute;
      top: 40%;
      cursor: pointer;
  }
  .slide-navigate-item i{
      color: #FFF;
      display: table;
      margin: 0 auto;
      position: relative;
      z-index: 3;
      font-size: 16px;
  }
  .slide-navigate-item .bg{
      background: #000;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 100%;
      opacity: 0.5;
  }
  .slide-navigate-item.prev{
      left: -20px;
  }
  .slide-navigate-item.next{
      right: -20px;
  }
  .slide-navigate-item img{
      display: table;
      margin: 0 auto;
  }
  .slide-navigate-item.template-2.next{
      right: 0;
  }
  .slide-navigate-item.template-2.prev{
      left: 0;
  }
  .slide-navigate-item.template-2{
      width: 45px;
      height: 45px;
  }
  .slide-navigate-item.template-2 img{
      width: 7px;
  }
  .center{
      display: table;
      margin: 0 auto;
  }
  .z-index-1{
      position: relative;
      z-index: 1;
      display: block;
  }
  .z-index-2{
      position: relative;
      z-index: 2;
      display: block;
  }
  .page{
      padding: 50px 0 100px 0;
  }
  .page-title{
      margin: 0 0 20px 0;
  }
  .page h1{
      font-size: 30px;
      font-family: map-get($fonts,two);
      font-weight: 500;
      color: #000;
  }
  .page h2{
    font-size: 18px;
  }
  .box-sombra{
      background: #FFF;
      box-shadow: 5px 5px 20px 0 #d4d4d4;
      padding: 20px;
  }
  strong{
      font-weight: 700;
  }
  a{
      text-decoration: none !important;
      color: #353535;
  }
  .title-h3{
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 10px 0;
  }
  .text-custom{
      font-size: 13px;
      line-height: 20px;
  }
  .linha{
      height: 1px;
      width: 100%;
      display: table;
      margin: 15px 0;
      background: #eee;
  }
  /* Tooltip container */
  .tooltip{
      position: relative;
      display: inline-block;  
  }
  /* Tooltip text */
  .tooltip .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: #555;
      color: #fff;
      text-align: center;
      padding: 10px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
      display: table;
  }
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
  }
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
  }
  .tooltip-bottom {
      top: 135% !important;
      left: 50% !important;
      margin-left: -60px !important;
  }
  .tooltip-bottom::after {
      content: "" !important;
      position: absolute !important;
      bottom: 100% !important;
      left: 50% !important;
      margin-left: -5px !important;
      border-width: 5px !important;
      border-style: solid !important;
      border-color: transparent transparent #555 transparent !important;
      top: -10px !important;
}
.card-item{
    padding: 0.8rem 1.3rem;
    position: relative;
    margin-right: 8px;
    background-color: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 1px 2px 3px -2px #1f0708;
    box-shadow: 1px 2px 3px -2px #1f0708;
}
.card-item .header{
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
}
.card-item .header span{
    font-weight: bold;
    color: map-get($colors,seven);
    flex: 1;
    font-size: 16px;
}
.card-item .header i{
    font-size: 18px;
    color: map-get($colors,two);
}
.linha-grey{
      background: #f9f9f9;
      height: 5px;
      width: 100%;
      display: table;
      margin: 20px 0;
}
.content-item{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.content-item h3{
    margin: 0 30px 0 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}
.content-item .content span{
    font-weight: 500;
    color: #000;
}
.content-item .content{
    display: flex;
    align-items: center;
    flex: 1;
}
.content-item .icon{
    background: #018268;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 10px 0 0;
}
.content-item .icon i{
    font-size: 14px;
    color: #FFF;
}
.contato-item{
    display: flex;
    align-items: center;
}
.contato-item .content span{
    font-weight: 500;
    color: #000;
    font-size: 14px;
}
.bg-bege{
    background: #facd82 !important
}

@media(max-width: 959px){
  
    header .menu-desktop{
        display: none !important;
    }
    header .logo{
        margin: 0 !important;
    }
    header .logo img{
        width: 100px !important; 
    }
    header .topo-partial .li-item,
    header .topo-partial .divisor-circle
    {
          display: none !important;
    }
    header .col-logo{
        display: flex;
        align-items: center;
    }   
  
}
@media(max-width: 767px){
  
      .slide-navigate-item.prev{
          left: 0;
      }
      .slide-navigate-item.next{
          right: 0;
      }
      .slide-navigate-item{
          width: 45px;
          height: 45px;
          top: 40%;
      }
      .slide-navigate-item img{
          width: 8px;
      }
      .slick-padding-mobile .slick-list{
          padding: 10px 20px 10px 10px !important;
      }
  
  } 
  